.mobile-menu {
	display:none;}
.lt-ie9 ul.oe_menu > li > a:after{
 
	display: none;
}
/** ipad **/


@media screen and (max-width:1400px){
 
	.nivo-caption{
		top: 20%;
		h2{
			font-size: 35px;
		}
		p{	font-size:18px}
		.orange-btn{margin-top: 0}
	}

	img{
		max-width: 100%;
	}

}

@media screen and (max-width: 1199px){
	.width, 
	.index-slider,
	#index-product,
	#header	{
		width: 100%;
		min-width: 100%;
	}



/*---index---*/
	.index-slider{
		overflow: hidden;
			width: 100%;
			min-width: 100%;
	}
	.index-logo{width: 70%;
	}

	.blue-bg img, .seablue-bg img, .deepblue-bg img, .gray-bg img, .white-bg img{
		
	    max-width: 90%;
	}
 
/*---end index---*/





/*------------------------------------------*/

}

/*special*/
@media screen and (max-width: 1250px){



	//news list
	.news-list{
		width: 100%;
		img{width: 100%}
	}

	//news detail
	.news-bg{
		width: 80%; padding: 50px 5%;
 	}
	.news-detail {
		img{width: 100%}
		.share2 { margin-bottom: 0px;}
	}

	/* all content */
 
	.table_01{
		width: 95%;
		th .black{font-size: 15px;}
	}
	/*end all content*/


}/*end: 1250*/


@media screen and (max-width:1024px){

	header{
		box-shadow:none
	}
	ul.oe_menu > li > a{
		padding: 12px 15px 12px 15px
	}


	#index-info .col-sm-7 {
	    background: none
	}

	.nivoSlider{
		width: 100%;
		min-width: 100%;
		overflow: hidden;
	}
	.nivoSlider img{width: 20%}
	.nivo-caption{
		top: 15%;
		h2{
			font-size: 30px;
		}
		p{	font-size:15px}
		.orange-btn{margin-top: 0}
	}






}/*end 1024px*/







/** special **/
@media screen and (max-width: 1000px){

	/** menu **/
	#mainmenu{
		display: none;
	}
	.logo{
    margin-top: 10px;
	}

	#header{height: 75px;}

/*------------------V------------------------*/
	/**mobile menu **/
	body{
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
	}

	#mobile-btn {
	color:#fff;
	display:block;
	height:50px;
	width: 50px;
	text-align:center;
	cursor:pointer;
	position: fixed;
	right: 0;
	top:0;
	background:$color-2 url(../images/menu.png) no-repeat center;
    z-index: 9999999;
	}

	/** accordion**/
	.mobile-menu {
		position: fixed;
		top:0;
		display:block;
		width: 250px;
		right:-250px;
		height: 100%;
		background: #eee;
		z-index:999999;
		overflow: scroll; //need
	    border-top: 50px solid $color-2;
		}

		#mobile-btn.on:after {
		    color: #fff;
		    content: "MENU";
		    font-size: 14px;
		    letter-spacing: 1px;
		    left: -45px;
		    top: 11px;
		    position: absolute;
		    text-align: center;
		    padding: 0 0px;
		}	

		.mobile-menu dt{
		    padding: 10px 20px;
		    cursor: pointer;
		    color: #fff;
		    border-bottom: 1px solid #666;
		    background: $color-1 url(../images/mobile-menu-arrow.png) no-repeat 93%;
		    &.no-child{
		    	background-color: #777;
    			text-align: center;
		    	background-image:none;
			    a{color: #fff;}
		    }

		}				
		.mobile-menu a {
		// color:#666;
			display:block;
		// padding: 10px 20px 10px 20px;

		}	

		.mobile-menu dd {
		    background: #666; 
		}	
		.mobile-menu dd a{
		    color: #fff;
		    padding: 5px 20px 5px 35px;
			border-bottom:1px solid #444;
	    }
			
		.mobile-menu .common-menu a{

		border-bottom:1px solid #444;

		}

		#topnav{
			margin-right: 51px;
		}

/*-------------------^-----------------------*/
	/*mobile use*/
	.m-center{
		text-align:center
	}
	
	/*img 1024*/
	img.m-img{
		width: 90%;
	}

	/* all content*/
	.width,
	.s-width {
	    width: 95%;
	}
	.bg-inner{
    	min-width: 95%;
		//padding: 100px 0 100px 0;
	}

}/*end 1000px*/



/** iphone & bootsrtap XS **/
@media screen and (max-width: 768px){
 
	#topnav{display: none;}

	#top{right:10px;}
 
	#index-product li{
		width: 100%;
		section {
		    opacity: 1;
		}
		.text {
		    bottom: 30%;
		    z-index: 99;
		}

	}
 

	footer{ 
		.footer-contact{
			text-align: center;
			margin:0 auto;
		}
		.share{
			text-align: center;
		}

	}
	.sitemap{display: none}
	.sitemap  dl{
	    width: 100%;
	    margin: 0 0 50px 0;
	    float: none;
	}

	/*----index----*/
	#index-info > .col-sm-7 .text {
	    margin-right: 20px;
	    float: none;
	    padding: 50px 0 30px 0;
	    text-align: center;
	}

	.nivoSlider{
		width: 100%;
		min-width: 100%;
		overflow: hidden;
	}
	.nivoSlider img{display: none}
	.nivo-caption{
		top: 20%;right: 0%;left: 0;width: 80%;margin: 0 auto;
		h2{
			font-size: 16px;
		}
		p{	font-size: 12px;line-height: 120%}
		.orange-btn{margin-top: 0;
			padding: 3px 33px;line-height: 100%}
	}


	/*end index*/




	//news
	.news-list li{
		width: 90%;
	}
	.news-menu li {
		margin:50px 20px 0px 20px;
	}

	/*---- all content----*/

	.white-xb{font-size: 35px;}
	.black-xb{font-size: 35px;}

	.width-80,
	.width-50{
		width: 90%;

	}


	.bg-inner{padding:0}
	#anchor-core.bg-inner{padding:0}
	.padded-t,
	.about-bg2{padding-top:100px;}

	.padded-tb{padding:100px 0}
	.padded-t50{padding-top: 10px}
	.padded-t100{padding-top: 0}

 
	/* all content */







	/*end all content*/

	.news-bg{
		.prev {
		    width: 50px;
		    height: 50px;
		}
	}


	/*---- form----*/


	.form{ 
		li > p{
			float: left;//
			text-align: left;//
			width:100%;//
		}
		input,
		textarea{
			float: left;//
			width:100%;//
		}

	 	.checkbox-wrap{
			span{display: block;}
			span:first-child input[type="checkbox"]{ 
				margin-left: 35px;
			}
		}

	}


	/*end form*/



	/*---- timeline----*/

	.editor .timeline > li{padding: 0;margin: 0}

	.editor .timeline > li > .timeline-panel, 
	.timeline > li > .timeline-panel {
		float: none;
		text-align: left; 
		width: 90%;
		margin: 30px auto 35px auto;	
		&:after{
		    position: absolute;
		    top: -15px;
		    left: auto;
		    right: 47%;
		    display: inline-block;
		    border-left: 13px solid transparent;
		    border-top: 0 solid #268ad2;
		    border-bottom: 14px solid #268ad2;
		    border-right: 14px solid transparent;
		    content: " ";
		    z-index: 999;
		}	
		p{float: none;}

		span{width:100%;float: none;//
			}

		span:first-child{ 
			width: 100%;//
		}
	}

	.timeline > li > .timeline-badge{
		position: relative;
		top: auto;
		margin: 0 auto;
		left:auto;
	}
	.timeline:before{display: none;}
	/*end timeline*/





}/*end 768*/



@media screen and (max-width: 490px){
	.width-80, 
	.width-50,
	.width-30{
		width: 90%
	}
	.table-product .item{
		padding: 0;
		width: 90%;
	} 

	.download-list li p, .editor .download-list li p{
		text-align: center;
		float: none;
		margin: 0 auto; 
	}
}/*end 490*/