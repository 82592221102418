

 
/*topnav*/

#topnav{ 
	min-width: 500px;
	min-height: 36px;
	text-align: right;
	li{
		display: inline-block;
		margin-left: 20px;
	}
	a{
		font-size: 14px;
	}
}


/*mainmenu*/
#mainmenu{  
	margin-top: 10px;
}


/*layer2*/
ul.oe_menu{     

    float: right;
    min-width: 700px;
	&:after {
	    content:'';
	    display:block;
	    clear: both;
	}

	> li {
		display: inline-block;
		float: left;
		&.selected > a{
			color:$color-4;
		}

		> a {
			    display: inline-block;
			    font-size: 17px;
			    text-align: center;
			    cursor: pointer;
			    height: 30px;
				color: $color-1;
				padding: 12px 25px 12px 25px;
				@extend %transition;

				&:hover{
				color:$color-4;
				}	
		}


	
		/*hover level 2*/
		> div{ 
			display: none;
		    width: 100%;
		    padding: 30px 0 50px 0;
		    left: 0;
		    z-index: 9999;
		    position: absolute;
		    text-align: center;
		    background: $color-1;
			@extend %shadow;

			li{
				display:inline-block;
				text-align: center;
				margin: 0 20px;

				a{ 
				color:#fff;
				display: block;
			    padding: 10px;
			    width: 140px; 

					&:hover{ 
						color:$color-4;
						background: #103D7B;
						img{ 
						}
					}
					img{width: 100%;}
				}

			}
 
		}//div
	}

	> li.group > a{
		&:hover{
	    background:  $color-1;
		color:#fff;
		}
	}
}

 

