.editor {
	p {
		padding-bottom: 15px;
	}
	ul {
		padding-bottom: 0px;
		li {
			line-height: 30px;
			padding-left: 30px;
			position: relative;
			&:before { 
				background:  $color-4;
			    margin-left: -20px;
			    content: '';
			    border-radius: 11px;
			    width: 8px;
			    height: 8px;
			    display: block; 
			    position: absolute;
			    top: 12px;
			}
		}

	}
	ol {
		padding-bottom: 15px;
		li {
			line-height: 20px;
			list-style-type:decimal-leading-zero;
			list-style-position:inside;
			padding: 10px 0;
		}
	}
	a {
		color: $color-1;
		&:hover {
			color: $color-2;
			border-bottom: none;
			text-decoration: none;
		}
	}

}


/*table general*/
table,
.table-list{
	width: 100%;
	margin-bottom: 20px;
	thead {
		border: 1px solid $color-1;
		padding-bottom: 10px;
	}
	th {
		padding: 3px;
		border-bottom: 1px solid $color-1;
		text-align: center;
		font-weight: normal;
		font-size: 11px;
	}
	td {
		border-bottom: 1px solid $gray;
		margin: 0;
		padding: 10px 15px;
   		u{    
   			min-width: 8px;
   			display: inline-block;
   		}
	}

	/*no style*/
	&.none {
		border: 0;
		padding: 10px 8px;
		td {
			border: 0;
			padding: 10px 15px;
		}
	}

 }


/*table general*/
.table_01{
	width: 100%;
	margin-bottom: 20px;

	th {
		text-align: center;
		font-weight: normal;
		border-bottom: 1px solid #ccc;
		word-break: break-all;//rwd
		vertical-align: top;
	}
	td {
		border-bottom: 1px solid #ccc;
		margin: 0;
		padding: 7px 15px;
		word-break: break-all; //rwd

	}

	/*img wrap*/
	.img{
		// background: url('../images/table-product-bg.png') no-repeat center;
		// width: 280px;
		// height: 280px;
		//padding: 0 50px;
		vertical-align: middle;
	    text-align: center;
	    margin-bottom: 20px;
	    //display: table-cell;
		img{
			vertical-align: middle;
			text-align: center;
		}
	}

td:nth-child(even) {
background: #eee;
}

	/*no style*/
	&.none {
		border: 0;
		padding: 10px 8px;
		td {
			border: 0;
			padding: 10px 15px;
		}
	}

 }
