@charset "utf-8";
/* List*/
.list {
	margin-bottom: 25px;
	li {
		padding: 8px 0;
		border-bottom: #ccc 1px dotted;
	}
	span {
		padding-right: 20px;
		font-size: 11px;
		color: $color-1;
	}
}
.pic-list {
	margin-top: 10px;
	float: left;
	width: 330px;
	margin-right: 10px;
}
 
 

/*article*/
.width-80{
	width: 760px;
	margin: 0 auto;
	position: relative;
	z-index: 9;
}


.width-50{
	width: 550px;
	margin: 0 auto;
	position: relative;
	z-index: 9;
}


.width-30{
	width: 390px;
	margin: 0 auto;
	position: relative;
	z-index: 9;
}

/*block-3 */ 
.block-3 {
    width: 100%;
    margin: 0 auto; 
	
	li {
	    width: 31%;
	    vertical-align: top;
	    display: inline-block;
	    line-height: 150%; 
	    margin-bottom: 30px;
	    margin: 0 1%;
		 text-align:left;
	}
 
 
}


/*ui*/
.line{
	border-bottom: 1px solid #fff;
	padding-bottom: 10px;
}

/*ui*/
.blue-line{
	border-bottom: 1px solid #80b9e4;
	padding-bottom: 10px;
}

/* -------------------------------------
	index
------------------------------------- */
 

/*------------index slider------------*/

//loading icon
.index-slider{
	position: relative;
	width: 100%;
	// min-width: 1200px;
	min-height:390px; 

	height: auto;
	background:#eee  url(../images/loading.gif) no-repeat 50% 50%; 
	// z-index: -1;

	min-width: 1200px; 
	overflow: hidden;
 
	#slider {
		//display: none;
		opacity :0;
	}

}
 
 

.nivoSlider {
	position: relative;
	width: 100%;
	height: auto;
	display: none;
	min-width: 1200px;
	img {
		position: absolute;
		top: 0;
		left: 0;
	}
	a.nivo-imageLink {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		padding: 0;
		margin: 0;
		z-index: 6;
	}
}

.nivo-main-image {
	display: block !important;
	position: relative !important;
	width: 100% !important;
}

.nivo-slice {
	display: block;
	position: absolute;
	z-index: 5;
	height: 100%;
	top: 0;
}



/* title */
.nivo-caption {
	text-align: center;
	position:absolute;
	display:none;
	// width: $width;    
	width: 30%;
    top: 25%;
    right: 20%;
	z-index:90;
	height:auto;
	color:#fff;

	img{position: relative;}
	h2{
	font-size: 44px;
	line-height: 120%;
	font-weight: normal;
	}
	p{
    font-size: 22px;
    letter-spacing: 1px;
	}
	.orange-btn{
		margin-top: 20px;
	}
}

 

/*pages use*/
#main-content{
	padding:0;
}

		

/* -------------------------------------
	index
------------------------------------- */


/*-----------index info------------*/

#index-info{
	width: $white;
	margin: 0 auto;

	>.col-sm-7{
		//width:639px;
		position: relative;
		padding: 0 30px;
		font-size: 14px;
		background:url("../images/page/index-01.jpg") no-repeat right top;
		.text{ 
			margin-right: 20px;
			float: left;
			padding: 50px 0; 
		}
		img{
			float: right;
			width: 220px;
			vertical-align: top;
			margin-top: -50px;
		}
	}

	.col-sm-5{
		border-left: 1px solid #ccc;
		margin: 50px 0;
		//width: 500px;
		text-align: center;
		font-size: 14px;
	}
 
}



.index-logo{
	width: 350px;
	margin: 10px auto;
}

/*-----------index product------------*/


#index-product{
	background: #ccc;
	min-width:1200px;
 

}

#index-product li{
	position: relative;padding:0;
	float: left;
	width: 33.33333%;
	list-style:none;
	line-height: 100%;
	font-size:0px; // don't del
	@extend %transition;
 	&:before{width: 0;display: none }
	&:after{
		display: block;
	    content: "";
		@extend %transition;
		background: transparent;
	}


	&:hover{
		@extend %transition;

		.text{ 
			bottom: 30%;
			z-index: 99;
		}
		section{ 
			opacity: 1;
		}
 
		&::after{
		    background: rgba(0, 0, 0, 0.3);
		    width: 100%; 
		    position: absolute;
		    height: 100%;
		    top: 0;
			@extend %transition;
		    z-index: 10;
		}

	} 
 

	.text{
		position: absolute;
		text-align: center;
		left: 0;
		right: 0;
		bottom: 0px;
		color:#fff;
		font-size:15px;
		line-height: 150%;
		@extend %transition;
	}
	section{
		opacity:0;
		@extend %transition;
	} 
	h3{
		font-size: 30px;
		letter-spacing: 2px;
		font-weight: normal;
	}
	img{
		@extend %transition;
		width: 100%;
	}
	.orange-btn{
		font-size:15px;
		margin-top: 20px
	}
}
 





/* -------------------------------------
	all content
------------------------------------- */



 


/* -------------------------------------
	about
------------------------------------- */
 

.about-bg{
	background: url(../images/page/bg/bg-about.png) no-repeat top center ;
	width: 100%;
	min-height: 900px;
}


.about-bg2{
	background: url(../images/page/about-02.png) no-repeat top center ;
	width: 100%;
	height: 380px;
	padding-top: 220px;
	color:#fff;
}



/* -------------------------------------
	news
------------------------------------- */
 

.news-menu {
    text-align: center;
    margin-bottom: 60px;
    li {
        display: inline-block;
        margin: 50px 20px;
        vertical-align: top;
        line-height: 120%;
        padding: 0;
        a {
        	color: #fff;
            padding:0 0 10px 0;
            font-size:18px ;
            display:inline-block;
            vertical-align: middle;  
            border-bottom: 1px solid transparent;
            &:hover,
            &.active {
            	border-bottom: 1px solid $color-4;
            }
        }
    }
}
 

/*block-3 */ 
.news-list {
    width: 1250px;
    margin: 0 auto; 
	
	li {
	    width: 31%;
	    vertical-align: top;
	    display: inline-block;
	    line-height: 180%; 
	    margin-bottom: 30px;
	    margin: 0 1%;
		text-align:left;
		margin-bottom: 80px;
		padding-bottom: 30px;
		border-bottom: 1px solid #80c4ec; 
		min-height: 390px;
		a{
			color: #fff;
			&:hover{
				color:$color-4;
			}
		}
		div{
			height: 130px;
			overflow: hidden;
		}
	}
	img{
		margin-bottom: 20px; 
		@extend %transition;
		&:hover{
			@extend %transition;
			opacity:0.9		
		}
	}
}

 

/* -------------------------------------
	news-detail
------------------------------------- */

.news-bg{
    background: #fff ;
    position: relative;
    font-size: 15px;
    line-height: 250%;
    padding:50px 100px;
    color: #333;
    width: 1000px;
    margin: 0 auto;
    position: relative;

    .prev{
		position: absolute;
		top: 0;
		right: 0;
		width: 70px;
		height: 70px;
		background:#006699 url('../images/cancel.png') no-repeat center;
		&:hover{
			background-color: $color-1;
		}
		
    }
 }

.news-detail{
	text-align: center;
	h2{
		margin-bottom: 10px;
	}
	.share2{
		margin-bottom: 50px;
	}

	.news-content{
		text-align: left
	}
}



/* -------------------------------------
	guaranty
------------------------------------- */

.form{ 
	li{
		clear: both;

		> p{
			float: left;
			text-align: right;
			margin-right:3%; 
			width: 22%;
		    padding: 10px 0;
		}

	}
	input,
	textarea{
		float: right;
		width:75%;
		color: #fff;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #89a2c6;
		margin-bottom: 5px;
		&:focus{
			border: none;
			border-bottom: 1px solid #fff;
		}
	}

	textarea{
		border: 1px solid #89a2c6;		
		&:focus{
			border: none;
			border: 1px solid #fff;	
		}
	}
	.checkbox-wrap{
		text-align: left;
		padding-top: 10px;
		span:first-child input[type="checkbox"]{ 
			margin-left: 0px;}
	}
	input[type="checkbox"]{ 
	    display: inline-block;
	    width: 15px;
	    float: none;
	    height: 15px;
	    vertical-align: middle;
	    text-align: left;
	    margin-left: 35px;
		padding: 10px 0;
	}

	input[type="submit"]{ 
	border:1px solid $color-4 ;
	color: $color-4;
    padding: 11px 35px;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    width: 150px;
    margin-top: 50px;
    
		&:hover{
			border:1px solid $color-4 ;
			background: $color-4;
			color:#fff ;
		}
	}

	label{
		font-weight: normal;
	}
}




/* -------------------------------------
	product-car
	product-moto
	product-cart
------------------------------------- */

.car-bg{ 
}


/*3 block product*/


.table-product{
	margin-bottom: 20px;
	text-align: center;

	.item{
	    display: inline-block;
	    padding: 10px 40px;
	    width: 316px;
	    font-size: 15px;
	    letter-spacing: 1px; 
    	vertical-align: top;

    	/*img wrap*/
    	.img{
			//background: url('../images/table-product-bg.png') no-repeat center;
			//width: 316px;
			//height: 280px;
			vertical-align: middle;
		    text-align: center;
		    margin-bottom: 20px;
		    //display: table-cell;
    	}
		img{
			vertical-align: middle;
			text-align: center;
		}
		// .img{ 
		// 	border:2px solid #ccc;
		//     width: 240px;
		//     height: 240px;
		//     border-radius: 150px;
		//     text-align: center;
		//     margin: 0 auto;
		//     margin-bottom: 40px;
		// }
		// img{
		// 	background: #eee;
		// 	border-radius: 150px;
		// 	padding: 35px;
	 //    	border: 15px solid #fff;
	 //    	position: relative;

		// }


		/*table*/
	    .row{
	    	border-bottom: 1px solid #ccc;
	    	padding: 8px 0;
	    	margin: 0
	    }
	    .col-xs-5{
	    	text-align: left;
	    	letter-spacing: 0px;
	    	padding: 0;

	    	&.text-right{
	    		text-align: right;
	    	}
	    }

	    .col-xs-7{
	    	text-align: left;
	    	padding: 0;
	    	letter-spacing: 0px;
	    	&.text-right{
	    		text-align: right;
	    	}
	    }
		//


	}


	a:hover{
		@extend %transition;
		opacity:0.8;
	}




	.text{
		margin-bottom: 50px;
 	}

	h3{
		font-size: 22px;
	    margin: 0; /*to 0*/
	    line-height: 130%;
	    margin-bottom: 30px;
	 	p{

			  color: #333;
			  font-size: 13px;
			  line-height: 150%;
			  margin:0;
			  padding: 0;
	 	}
 	}




}



/* -------------------------------------
	support
------------------------------------- */

.support-bg{
	background: url(../images/page/bg/bg-support.png) no-repeat top center ;
	width: 100%;
	min-height: 1100px;
}

.support-bg2{
	background: url(../images/page/support-04.png) no-repeat  center 180px;
	width: 100%;
	min-height: 900px;
}


.accordion.qa-list{
	text-align: left;
	margin: 40px auto 100px auto;
	max-width: 800px;  
	width: 100%;
 
	dt{
	padding: 10px 15px;
	cursor: pointer;
	border-bottom: 1px solid rgba(51,145,213,0.8);
	font-size: 17px;
	@extend %transition;
		&.active,
		&:hover{
		background: #3391d5; 
		background: rgba(51,145,213,0.8);
		@extend %transition;
		}
	}
	dd{ 
	padding: 15px 15px 15px 35px;
	border-bottom: 1px solid rgba(51,145,213,0.8);
	}

}



//說明書下載
.download-list,
.editor .download-list{
	text-align: left; 
	li{
		border-bottom: 1px solid #80b9e4; 
		padding: 10px 0 5px 0;
		/*left title*/
		a {
			text-align: left;
    		max-width: 80%;
			display: block;
			float: left;
			color: #fff;
			padding:12px 0 18px 70px;
			font-size: 18px;
    		background: url(../images/icon-download-w.png) no-repeat center left;
    		&:hover{ 
				color: $color-4;
    		}
  		}

		/*right -Mb*/
		p{ 
			float: right;

    		max-width: 20%;
			padding: 0;
			padding:12px 0 18px 0;
			font-size: 18px;
		}

		&:before{
			display: none
		}
		&:after{
			display: block;
			content: " ";
			clear: both
		}
	}

}



/* -------------------------------------
	ODM/OEM
------------------------------------- */
 

.odm-bg{ 
	background: url(../images/page/bg/bg-odm.png) no-repeat top center ;
	width: 100%;
	min-height: 1400px;
}

 
 

/* -------------------------------------
	loading bar
------------------------------------- */
/* This is a compiled file, you should be editing the file in the templates directory */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
	display: none
}

.pace .pace-progress {
	background-color: rgba(214, 214, 214, 0.2);
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 100%;
    height: 100%;
    bottom: 0;
    width: 100%;
}



/* -------------------------------------
	timeline  
------------------------------------- */



.editor .timeline {
	padding-bottom: 150px;
}

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}
	/*center line*/
    .timeline:before {
        top: 35px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 1px; 
        background-color: #268ad2;
        left: 50%;
        margin-left: -1.5px;
    }

    .timeline > li,
    .editor .timeline > li{
        margin-bottom: 20px;
        position: relative; 
    }

        .editor .timeline > li:before,
        .timeline > li:before,
        .timeline > li:after {
            content: " ";
            display: table;
            width: 0;

        }

        .timeline > li:after {
            clear: both;
        }
 
		.editor .timeline > li > .timeline-panel,
        .timeline > li > .timeline-panel {
            width: 36%;
            float: left;
            border: 1px solid #268ad2;
            border-radius: 2px;
            padding: 0px;
            position: relative;
            -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            padding: 3%;
        }

            .timeline > li > .timeline-panel:before {
                position: absolute;
                top: 26px;
                right: -15px;
                display: inline-block;
                // border-top: 15px solid transparent;
                // border-left: 15px solid #ccc;
                // border-right: 0 solid #ccc;
                // border-bottom: 15px solid transparent;
                content: " ";
            }

            .timeline > li > .timeline-panel:after {
                position: absolute;
                top: 27px;
                right: -14px;
                display: inline-block;
                border-top: 14px solid transparent;
                border-left: 14px solid #268ad2;
                border-right: 0 solid #268ad2;
                border-bottom: 14px solid transparent;
                content: " ";
            }

        .timeline > li > .timeline-badge {
            color: #fff;
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 15px;
            text-align: center;
            position: absolute;
            top: 16px;
            left: 50%;
            margin-left: -30px;
            background-color: $color-4;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
        }

        .timeline > li.timeline-inverted > .timeline-panel {
            float: right;
            width: 40%;
        }

            .timeline > li.timeline-inverted > .timeline-panel:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }

            .timeline > li.timeline-inverted > .timeline-panel:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }


.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}
 
.timeline-panel,
.editor ul.timeline-panel {
	text-align: left; 
	> p,ul{
	    margin-bottom: 0;
	    padding: 0;
	}
	> p + p {
        margin-top: 5px;
    }
	background-color: #268ad2;
	p{
		float: left; 
		padding: 0;
		width: 100%;

	    padding-bottom: 5px;
	}
	span{width:85%;
		float: left;}

	span:first-child{ 
		display: block;
		float: left;
		width: 15%;
	}

}

 

 