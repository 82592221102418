#searchForm {
	float: right;
	input[type="text"] {
		background: url(../images/search-white.png) no-repeat 10px center;
		border: 0 none;
		color: $black;
		width: 100px;
		padding: 6px 15px 6px 35px;
		border: 1px solid $black;
		margin: 0;
		@extend %radius;
		@extend %transition;
		&:focus {
			width: 150px;
			background:$color-1 url(../images/search-white.png) no-repeat 10px center ;
			color: $white;
		}
	}
}					

/* Styling the search results */
.pageContainer{
	margin-bottom:50px;
	
}


p.notFound{
	text-align:center;
	padding:0 0 40px;
}


/* Web & news results */
.webResult {
	padding: 20px 0;
	h2 {
		font-size: 16px;
		line-height: 50px;
		margin-bottom: 20px;
		font-weight: normal;
		padding: 4px 20px;
		background: #f5f5f5;
		@extend %radius;
		b {
			color: $red;
			font-weight: normal;;
		}
		a {
			color: $black;
		}
	}
	p {
		padding: 5px 20px ;
		b {
			color: $red;
		}
	}
	> a {
		padding-left: 20px;
	}
}

/* Image & video search results */

.imageResult {
	float: left;
	height: 180px;
	margin: 0 0 20px 40px;
	text-align: center;
	width: 152px;
	overflow: hidden;
	img {
		display: block;
		border: none;
	}
	a.pic {
		border: 1px solid $white;
		outline: 1px solid #777;
		display: block;
		margin: 0 auto 15px;
	}
}

/* The show more button */

#more{
	padding:6px 20px;
	width:70px;	
	cursor:pointer;
	margin:40px auto;
	display:block;
	text-align:center;
	background: $color-1;
	color:$white;
	@extend %radius-btn;
	@extend %transition;
	&:after{
		content:'more';
	}
	&:hover{
		background: $color-2;
	}
}




/* Giving Credit */
.clear {
	clear: both;
}
