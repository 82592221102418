





/* -------------------------------------
    slick.js
------------------------------------- */

    /* Arrows */
    .slick-prev, .slick-next { position: absolute; display: block; height: 100%; width: 50px;  cursor: pointer; background-repeat:no-repeat; background-position:center center; top:0; }
    .slick-prev { left:-60px; background-image:url(../images/Btn-prevImg.png)}
    .slick-next { right:-60px;  background-image:url(../images/Btn-nextImg.png); }
    
    /* Dots */
    .slick-dots { position: absolute; display: block; text-align: center; width: 100%; left:0;
	margin-top: 10px;
    }
    .slick-dots li {  position: relative; display: inline-block; margin:0 10px; background:#bbb;
        -moz-border-radius: 100px; /* Firefox */ 
        -webkit-border-radius: 100px; /* Safari, Chrome */ 
        -khtml-border-radius: 100px; /* Konqueror */ 
        border-radius: 100px; /* Opera 10.50 and later (CSS3 standard) */ 
    }
    .slick-dots a{ display:block;width:9px; height:9px; cursor:pointer;}
    .slick-dots a:hover{ background:none;}
    .slick-dots .slick-active { background:#000;}
    
    
    /* Slider */
    .slick-slider {width:100%; position: relative; display: block; margin:0 auto;}
    .slick-list { position: relative; overflow: hidden; display: block; }
    .slick-loading .slick-list { background: white url('../images/loading.gif') center center no-repeat; }
    .slick-list.dragging { cursor: pointer; cursor: hand; }
    .slick-track { position: relative; left: 0; top: 0; display: block; zoom: 1; }
    .slick-track:after { clear: both; }
    .slick-loading .slick-track { visibility: hidden; }
    
    .slick-slide { float: left; height: 100%; min-height: 1px; display: none; text-align:center;}
    .slick-slide img {
    display: block;
    margin: 0 auto;
    width: 100%;
    }


    .index-news  .slick-slide img {
    max-height: 400px;/*ie*/
	}
    .gray-bg .slick-slide img {
    max-height: 300px;/*ie*/
	}

    .slick-slide.slick-loading img { display: none; }
    .slick-slide.dragging img { pointer-events: none; }
    .slick-initialized .slick-slide { display: block; }
    .slick-loading .slick-slide { visibility: hidden; }
    .slick-vertical .slick-slide { display: block; height: auto;}

    .index-news{
        text-align:center;
        background:#ccc;
        margin: 0 auto;

        // margin-top:100px;
        }
    /* 放大 */
    .index-news .news-item img{ transition:all 300ms ease;z-index: -1}
    .index-news .news-item img{
        position: relative;
        z-index: -1;

    }
	.index-news{
		min-width: 1200px;

	    .news-item{
	        position: relative;
	        text-align: center;
	        .circle-btn-w{
	        	margin: 0 auto;
	        	margin-top: 10px;
	        	display: block;
	        	display: inline-block;/*ie*/

	        }
	    }
		.slick-center{
	        background-color: rgba(0, 0, 0, 0.5);
		}
	    .slick-active:hover{
	        	background-color: rgba(0, 0, 0, 0.5);
	        }

	    .news-text{
	        position: absolute;
	        left: 0;    
	        top: 0;
	        width: 70%;
	        padding: 10% 15%;
	        color: #fff;
	        text-align: center;
	        @extend %trans-slow  ;
            //background-color: rgba(0, 0, 0, 0.5);
            opacity:0;
	            h4{
	            	text-overflow: ellipsis;
				    white-space: nowrap;
				    overflow: hidden;
	            }
            }

		.slick-center .news-text{
           // background-color: rgba(255,255, 255, 0.6);
           //background-color: rgba(0, 0, 0, 0.5);
           display: block;
           @extend %trans-slow  ;
           opacity:1;

		}

		.news-item:hover .news-text,
		span:hover {
           // background-color: rgba(255,255, 255, 0.6);
           //background-color: rgba(0, 0, 0, 0.5);
           display: block;
           @extend %trans-slow  ;
           opacity:1;
           
		}

	}





/* -------------------------------------
    ---
------------------------------------- */
