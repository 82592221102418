/**
 * fullPage 1.6.7
 * https://github.com/alvarotrigo/fullPage.js
 */
#superContainer {
    height: 100%;
    position: relative;
	/* Touch detection for Windows 8 */
	-ms-touch-action: none; 
}
.section {
    position: relative;
	width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
	text-align:center;
	color:#fff;
	font-size:15px;
	background-position:center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	/*background-attachment:fixed;*/
}
.intro{
	display:none;
}

.slide {
    float: left;
}
.slide, .slidesContainer {
    height: 100%;
    display: block;
}
.slides {
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.section.table, .slide.table {
    display: table;
    width: 100%;
}
.tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.slidesContainer {
    float: left;
    position: relative;
}

.controlArrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-top: -38px;
}
.controlArrow.prev {
    left: 5%;
	background:url(../images/Btn-prevImg.png) no-repeat top left;
}
.controlArrow.next {
    right: 5%;
	background:url(../images/Btn-nextImg.png) no-repeat top right;
}
.scrollable {
    overflow: scroll;
}
.easing {
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
}


//@extend-elements
//original selectors
//#fullPage-nav li, .fullPage-slidesNav li


/*Nav*/


#fullPage-nav,
.fullPage-slidesNav {
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        display: block;
        width: 14px;
        height: 13px;
        margin: 15px;
        position: relative;
        a {
            display: block;
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            cursor: pointer;
            text-decoration: none;
        }
         .active span {
            background: #79a812;
        }
    }
    span {
        width: 12px;
        height: 12px;
        background: #555;
        //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
        border-radius: 50%;
        position: absolute;
        z-index: 1;
    }
   
}
#fullPage-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
    &.right {
        right: 17px;
    }
    &.left {
        left: 17px;
    }
   
}
/*slides Nav*/
.fullPage-slidesNav {
    position: absolute;
    z-index: 4;
    left: 50%;
    opacity: 1;
    &.bottom {
        bottom: 17px;
    }
    &.top {
        top: 17px;
    }
     li{
        display: inline-block;
    }
}



.fullPage-tooltip {
    position: absolute;
    color: $white;
    top: -7px;
    white-space: nowrap;
    font-size: 15px;
    &.right {
        right: 30px;
    }
    &.left {
        left: 20px;
    }
}

/*menu*/
#menu {
    position: fixed;
    bottom: 70px;
    left: 0;
    height: 100px;
    z-index: 70;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    li {
        display: inline-block;
        margin: 10px;
        color: $white;
        font-size: 25px;
        border-bottom: 1px;
        a {
            padding: 10px 30px;
            display: block;
            border-bottom: 1px;
            color: $white;
        }
        &.active a {
            border-bottom: 1px solid $white;
        }
    }
}


/* Chrome, Safari, Opera 
@-webkit-keyframes zoom {
    from {
    	-webkit-transform: scale(1,1);
    }
    to {
    	-webkit-transform: scale(1.5,1.5);
    }
}*/

/* Standard syntax 
@keyframes zoom {
   from {
        transform: scale(1,1);
   }
   to {
        transform: scale(1.5,1.5);
   }
}


/* {
    -webkit-animation: zoom 50s; 
    animation: zoom 50s;
}*/