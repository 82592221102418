//colors
$color_text: #333;

$color-1: #12458d;//deep blue
$color-2: #082c5e;//deep blue2
$color-3: #001635;//deep blue3
$color-4: #f39800;//orange

$gray: #ddd;
$red: #cc0000;
$white: #fff;
$black: #000;

//other
$width: 1200px;
 
//@extend
%radius {
	-moz-border-radius: 50px; /* Firefox */ 
	-webkit-border-radius: 50px; /* Safari, Chrome */ 
	-khtml-border-radius: 50px; /* Konqueror */ 
	border-radius: 50px; /* Opera 10.50 and later (CSS3 standard) */ 		
}
%radius-btn {
	-moz-border-radius: 3px; /* Firefox */ 
	-webkit-border-radius: 3px; /* Safari, Chrome */ 
	-khtml-border-radius: 3px; /* Konqueror */ 
	border-radius: 3px; /* Opera 10.50 and later (CSS3 standard) */ 		
}
%shadow {
	text-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1) ;
	-moz-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1) ;
	box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1) ;			
}
%transition {
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	-ms-transition:all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}

%trans-slow {
	-webkit-transition: all 1s ease 0s;
	-moz-transition: all 1s ease 0s;
	-o-transition: all 1s ease 0s;
	-ms-transition:all 1s ease 0s;
	transition: all 1s ease 0s;
}
 

//font
%font-all {
	font-family:Microsoft JhengHei, Arial , Lucida Sans Unicode, sans-serif;
}

%font-bold {
	font-family: "Meiryo UI", Arial, Microsoft JhengHei, Lucida Sans Unicode, sans-serif;
}

.v-text,
%v-text{ 
	-ms-writing-mode: tb-rl; /* old syntax. IE */
	-webkit-writing-mode: vertical-rl;
	-moz-writing-mode: vertical-rl;
	-ms-writing-mode: vertical-rl;
	writing-mode: vertical-rl; /* new syntax */
}


/* general
----------------------------------------------------------*/


body {
	@extend %font-all;
	font-size: 15px;
	color: $color_text;
	line-height: 26px;
	line-height: 200%;
    overflow-y: scroll;/*special*/


    color: #333;  
    -ms-overflow-style: scrollbar;
    position: relative;
    overflow-x: hidden !important;
}


a {
	color:$color_text;
	text-decoration: none;
	@extend %transition;
	&:hover {
		color:$color-4;
	}
}

p {
	padding: 10px 0;
}

u {
	padding: 0 10px 0 5px;
	text-decoration: none;
	color: $color-4;
}

.left {
	float: left;
}

.right {
	float: right;
}

.cleaner {
	float: none;
	clear: both;
	height: 0;
}

#top {
	position: fixed;
	z-index: 9999999999999;
	bottom: 50px;
	right: 50px;
	width: 50px;
	height: 50px;
	background: url(../images/top.png) no-repeat 0 0;
	cursor: pointer;
}


hr{
	border:0; 
	height:1px; 
	background-color:#d4d4d4;
	color:#d4d4d4;	/* IE6 */
	    margin: 20px 0;
}



/*-----------title------------*/
h1 {
	font-size: 40px;
	padding-bottom: 20px;
}
h2 {
	font-size: 30px;
	line-height: 80px;
}
h3 {
	font-size: 20px;
	line-height: 40px;
}
h4 {
	font-size: 18px;
	font-weight: normal;
	line-height: 36px;
}

.bold {
  @extend %font-bold;
}


.editor a.blue-s,
a.blue-s,
.blue-s {
  color: $color-1;
  font-size: 15px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.blue-m,
a.blue-m,
.blue-m{
  color: $color-1;
  font-size: 18px;
  line-height: 150%;
  margin:0;
  padding: 0;

}


.editor a.blue-b,
a.blue-b,
.blue-b{
  color: $color-1;
  font-size: 28px;
  line-height: 150%;
  margin:0;
  padding: 0;
}


.editor a.white,
a.white,
.white{
  color: #fff;
  font-size: 15px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.white-m,
a.white-m,
.white-m{
  color: #fff;
  font-size: 25px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.white-m2,
a.white-m2,
.white-m2{
    color: #fff;
    font-size: 25px;
    line-height: 150%;
    margin: 0 0 20px 0;
    padding: 0 25px 8px 25px;
    border-bottom: 1px solid #268ad2;
    display: inline-block;
}

.editor a.white-b,
a.white-b,
.white-b{
  color: #fff;
  font-size: 32px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.white-xb,
a.white-xb,
.white-xb{
  color: #fff;
  font-size: 42px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.black-s,
a.black-s,
.black-s{
  color: #000;
  font-size: 13px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.black,
a.black,
.black{
  color: #000;
  font-size: 22px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.black-m,
a.black-m,
.black-m{
  color: #000;
  font-size: 32px;
  line-height: 150%;
  margin:0;
  padding: 0;
}

.editor a.black-b,
a.black-b,
.black-b{
  color: #000;
  font-size: 38px;
  line-height: 150%;
  margin:0;
  padding: 0;
}


.editor a.black-xb,
a.black-xb,
.black-xb{
  color: #000;
  font-size: 42px;
  line-height: 150%;
  margin:0;
  padding: 0;
}


.editor a.black,
a.black,
.editor a.white,
a.white{
	&:hover{
		color:$color-4;
	}
}


/*-----------bg------------*/

.blue-bg{
    //background: url(../images/bg-01.png) repeat-y center ;
    position: relative;
    color: #fff;
    font-size: 15px;
    line-height: 250%;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0093df+0,0147a6+100 */
	background: rgb(0,147,223); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(0,147,223,1) 0%, rgba(1,71,166,1) 100%); /* FF3.6-15 */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,147,223,1)), color-stop(100%,rgba(1,71,166,1))); /* Chrome4-9,Safari4-5 */
	background: -webkit-linear-gradient(left, rgba(0,147,223,1) 0%,rgba(1,71,166,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: -o-linear-gradient(left, rgba(0,147,223,1) 0%,rgba(1,71,166,1) 100%); /* Opera 11.10-11.50 */
	background: -ms-linear-gradient(left, rgba(0,147,223,1) 0%,rgba(1,71,166,1) 100%); /* IE10 preview */
	background: linear-gradient(to right, rgba(0,147,223,1) 0%,rgba(1,71,166,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0093df', endColorstr='#0147a6',GradientType=1 ); /* IE6-9 */
 }


.seablue-bg{
    background:$color-1;
    position: relative;
    color: #fff;
    font-size: 15px;
    line-height: 250%;
 }

.deepblue-bg{
    position: relative;
    color: #fff;
    font-size: 15px;
    line-height: 250%;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0e2f5d+0,2b5c9a+100 */
	background: rgb(14,47,93); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(14,47,93,1) 0%, rgba(43,92,154,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(14,47,93,1) 0%,rgba(43,92,154,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(14,47,93,1) 0%,rgba(43,92,154,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e2f5d', endColorstr='#2b5c9a',GradientType=0 ); /* IE6-9 */

 }

.gray-bg{
    background:#eee;
    position: relative;
    font-size: 15px; 
	text-align: center;
	padding: 40px 0;
    img{
    	vertical-align: bottom;
    }
 }

.white-bg{
    background:#fff;
    position: relative;
    font-size: 15px; 
	padding: 40px 0;
 }

/*img align*/
.blue-bg,
.seablue-bg,
.deepblue-bg,
.gray-bg,
.white-bg{
    img{
    	vertical-align: bottom;
    }
}


.bg-inner{
	@extend .width;
	@extend .text-center;
	@extend .padded-tb;
}

 
/*-----------padded------------*/
.padded-tb{
	padding:190px 0;
}
 
.padded-tb100{
	padding:100px 0;
}
.padded-t{
	padding: 0;
	padding-top:190px;
}

.padded-t50{
	padding: 0;
	padding-top:50px;
}

.padded-t100{
	padding-top:100px;
}

.padded-t400{
	padding-top:400px;
}

.padded-t500{
	padding-top:500px;
}

.white-text{
	color: #fff;
}
.nopadded{
	padding: 0
}


/*-----------btn------------*/
.editor a.blue-btn,
.blue-btn{
	background: $color-1 ;
	color: #fff;
	padding: 10px 30px;
	&:hover{
		background: #ccc;
		color: #fff;
	}
}
.editor a.orange-btn,
.orange-btn{
	border:1px solid $color-4 ;
	color: $color-4;
	padding: 1px 35px;
	display: inline-block;
	&:hover{
	
		border:1px solid $color-4 ;
		background: $color-4 ;
		color: #fff;
	}
}
.editor a.orange2-btn,
.orange2-btn{
	border:1px solid #fff;
	background: $color-4 ;
	color: $color-4;
	padding: 0px 33px;
	display: inline-block;
	color: #fff;
	&:hover{
		border:1px solid $color-4 ;
		background: #fff;
		color:$color-4 ;
	}
}

.editor a.orange3-btn,
.orange3-btn{
	border:1px solid $color-4 ;
	color: $color-4;
	padding: 3px 35px;
	display: inline-block;
	color: #fff;
	font-size: 18px;
	&:hover{
		border:1px solid $color-4 ;
		background: $color-4;
		color:#fff ;
	}
}

/*icons*/
i.download-s{
    background: url(../images/icon-download-s.png) no-repeat center left;
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
}

i.download{
    background: url(../images/icon-download.png) no-repeat center left;
    width: 120px;
    height: 120px;
    display: inline-block;
    vertical-align: middle;
}

i.download-w{
    background: url(../images/icon-download-w.png) no-repeat center left;
    width: 120px;
    height: 120px;
    display: inline-block;
    vertical-align: middle;
}

i.tpms-s{
    background: url(../images/icon-tpms-s.png) no-repeat center left;
    width: 46px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
}


i.qa{
    background: url(../images/icon-question.png) no-repeat center left;
    width: 120px;
    height: 120px;
    display: inline-block;
    vertical-align: middle;
}

i.pen{
    background: url(../images/icon-pen.png) no-repeat center left;
    width: 120px;
    height: 120px;
    display: inline-block;
    vertical-align: middle;
}


i.tel{
    background: url(../images/icon-tel.png) no-repeat center left;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}


i.fax{
    background: url(../images/icon-fax.png) no-repeat center left;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}


i.mail{
    background: url(../images/icon-mail.png) no-repeat center left;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}


i.maps{
    background: url(../images/icon-maps.png) no-repeat center left;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}




/*----------- align------------*/

.text-left{
	text-align: left;
}

.text-right{
	text-align: right;
}

.btn-center{
	text-align: center;
	position: relative;
	display: table;
    margin: 0 auto;
}
.text-center{
	text-align: center;
	position: relative;
    margin: 0 auto;
}

.noshow{
	display: none;
}
  

.pdf{
    background: url(../images/pdf-bg.jpg) no-repeat center 7px;
    border-radius: 50px;
    border: 1px dashed #666;
    padding: 27px 8px 12px 11px;
    width: 40px;
    height: 20px;
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 12px;
    letter-spacing: 1px;
    	opacity:1; 

    font-family: "微軟正黑體";

}

.opacity5{
	opacity:0.5
}




/* header (flour)
----------------------------------------------------------*/
header {
	width: 100%;
	z-index: 99;
    position: relative; 
    z-index: 991;

-webkit-box-shadow: 0px 0px 15px 0px rgba(130,130,130,1);
-moz-box-shadow: 0px 0px 15px 0px rgba(130,130,130,1);
box-shadow: 0px 0px 15px 0px rgba(130,130,130,1);
}

#header{ 
    margin: 0 auto;
	display: block; 
    height: 100px;
    background: #fff; 
    width: $width; 



}


/*logo*/
.logo { /*橫向*/  
	float: left;
	margin-top: 20px;
    width: 180px;
    height: 50px;
    background: url(../images/logo.jpg) no-repeat left;
    display: block;
}




/* body
----------------------------------------------------------*/

.width {
	width: $width;
	margin: 0 auto;
	position: relative;
	z-index: 9;
	&.side {
		padding: 150px;
	}
}
.s-width{
	width: 960px;
	margin: 0 auto;
	position: relative;
	z-index: 9;
}


/* footer
 ----------------------------------------------------------*/
footer {
	width: 100%;
	z-index: 99;
	font-size: 14px;
	padding: 50px 0;
	background-color: $color-3;
	color:#949596;
	line-height: 170%;
	a{
	color:#949596;
	}
	.footer-contact{ 
	}
}
 

 /*footer sitemap*/

.sitemap  dl{
    width: 20%;
    margin: 0 5% 50px 0;
    float: left;

	dt{
		font-size: 14px;
	    font-weight: bold;
	    margin-bottom: 5px;
	}
	a {
		display: block;
		color: #949596;
		line-height: 170%;
		font-size: 12px;
		&:hover{
			color:$color-4;
		}
	}

}


/* share
 ----------------------------------------------------------*/
.share,
.share2 {
  text-align: left;
  margin-top: 20px;
	a {
	    height: 30px;
	    width: 30px;
	    margin: 0 1px;
	    border-radius: 15px;
	    text-align: center;
	    display: inline-block;
	    background-position: center center;
	    background-repeat: no-repeat; 
		opacity:0.6;
	    &:hover{ 
		@extend %transition;
		opacity:1;
		}
    }
} 
.share2{
	text-align: center;
	margin: 10px 0 10px 0;
	a{ opacity:1;
	    &:hover{ 
		@extend %transition;
		opacity:0.6;
		}
	}
}
 
.share a.fb {
  background-image: url(../images/share-fb.png);
}
.share a.google {
  background-image: url(../images/share-google.png);
}
.share a.twitter {
  background-image: url(../images/share-twitter.png);
}


.share2 a.fb {
  background-image: url(../images/share2-fb.png);
}
.share2 a.google {
  background-image: url(../images/share2-google.png);
}
.share2 a.twitter {
  background-image: url(../images/share2-twitter.png);
}



 

/* page404
 ----------------------------------------------------------*/
.page404{
    width: 50%;
    max-width: 400px;
    margin: 10% auto 15% auto;
    border: 1px solid #ddd;
    padding: 30px 50px;
    background: #f8f8f8;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
}

.page404 h2 {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    font-size: 25px;
    line-height: 150%;
}



 

 
 
/* pager
 ----------------------------------------------------------*/

#pager {
	text-align: center;
	padding: 30px 0;
	a {
		font-size: 13px;
		width: 24px;
		height: 24px;
		cursor: pointer;
		display: inline-block;
		margin: 0 5px;
		@extend %transition;

		&:hover {
			color: $black;
		}
		&.pre, &.next{
			background: $color-1;
			color: $white;
			@extend %radius;
			&:hover {
				background: $color-2;
			}
		}
		
		&.current {
			color: $color-1;
		}
	}
	
}


/* Inputs, textareas and selects 
 ----------------------------------------------------------*/

input[type="text"],
input[type="password"], 
textarea, 
div.styled, 
input[type="file"],
input[type="submit"],
input[type="button"].btn {  
	line-height:20px;
	font-family:"Microsoft JhengHei", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
	padding:8px;
	margin:6px 0;
	border:1px solid #666;
 
	@extend %transition;
	//@extend %shadow;
	}

input[type=text],input[type=password],textarea{
	width:100%;
	}
	
input[type="text"]:focus,input[type="password"]:focus,textarea:focus {
	border:1px solid $color-1;
	}							

input[type="radio"]{
	margin:0 10px;
	}
	



/* button
---------------------------------------------------------- */


input[type="submit"],
%submit-btn{
 
}
input[type="button"],.btn {
	padding:8px 30px;
	background:$color-1;
	color:$white;
	margin:0 10px;

	&:hover{
		background:$color-2;
	}

}

input[type="button"]  {
	background:#888;
	}



/* Select menu  
---------------------------------------------------------- */

div.styled {
	overflow: hidden;
	select {
		appearance:none; /* arrow */
		-moz-appearance:none; /* Firefox */
		-webkit-appearance:none; /* Safari and Chrome */

		width: 100%;
		background-color: transparent;
		background-image: none;
		box-shadow: none;
		background: white url(../images/arrow-down.gif) no-repeat center right 20px;
		option {
			width: 100%;
			padding: 0;
		}
	}
}
.ie7 div.styled {border:none;}
.ie8 div.styled select { padding:6px 0;}
.ie7 div.styled select {
	width:100%; background-color:#fff; 		
	border:1px solid #ccc;
	padding:6px;
}	
   
option:checked{
	color: #8c267c;
	font-weight:bold;
}	

/* alert 
---------------------------------------------------------- */

.alert{padding:8px 35px 8px 14px;margin-bottom:10px;margin-top:10px;margin-right:5px;text-shadow:0 1px 0 rgba(255,255,255,0.5);background-color:#fcf8e3;border:1px solid #fbeed5;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px}.alert,.alert h4{color:#c09853}.alert h4{margin:0}.alert .close{position:relative;top:-2px;right:-21px;line-height:20px}.alert-success{color:#468847;background-color:#dff0d8;border-color:#d6e9c6}.alert-success h4{color:#468847}.alert-danger,.alert-error{color:#b94a48;background-color:#f2dede;border-color:#eed3d7}.alert-danger h4,.alert-error h4{color:#b94a48}.alert-info{color:#3a87ad;background-color:#d9edf7;border-color:#bce8f1}.alert-info h4{color:#3a87ad}.alert-block{padding-top:14px;padding-bottom:14px}.alert-block>p,.alert-block>ul{margin-bottom:0}.alert-block p+p{margin-top:5px}					

.information {
	width:400px;
	margin:10% auto 15% auto;
	border:1px solid #ddd;
	padding:30px 50px;
	background:#f8f8f8;
	
	@extend %radius;
	
	h2 {
	margin-bottom:20px;
	padding-bottom:20px;
	border-bottom:1px solid #ccc;
	}		
}		
