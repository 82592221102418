
@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  src: url('../fonts/myriadpro-bold-webfont.eot');
  src: url('../fonts/myriadpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
  	url('../fonts/myriadpro-bold-webfont.ttf') format('truetype'),
	url('../fonts/myriadpro-bold-webfont.woff') format('woff'),
	url('../fonts/myriadpro-bold-webfont.svg') format('fonts/svg');
};


// @import url(http://fonts.googleapis.com/earlyaccess/cwtexhei.css); 
// @import url(http://fonts.googleapis.com/earlyaccess/cwtexfangsong.css);
// @import url(http://fonts.googleapis.com/earlyaccess/cwtexkai.css); 
// @import url(http://fonts.googleapis.com/earlyaccess/cwtexming.css);
// @import url(http://fonts.googleapis.com/earlyaccess/cwtexyen.css);
